<template>
    <div class="titlebar">
        <div class="time-box">
            <span>{{ curDate }}</span>
            <span style="margin: 0 10px;">{{ curTime }}</span>
            <span>{{ curDay }}</span>
            <span
                class="index-btn btn"
                @click="showNav"
                v-show="$router.currentRoute.fullPath === '/'"
                >菜单栏</span
            >
        </div>
        <div class="title">东营区智慧公厕综合监管平台</div>
        <div class="control">
            <div class="c-center btn" @click="fullScreen">
                <img class="icon" src="../assets/img/fullscreen.png" alt="" />
                <span>全屏</span>
            </div>
            <div class="c-center" style="margin:0 30px;">
                <img class="icon" src="../assets/img/admin.png" alt="" />
                <span>欢迎您，{{ username }}</span>
            </div>
            <div class="c-center btn" @click="exit">
                <img class="icon" src="../assets/img/exit.png" alt="" />
                <span>退出</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "TitleBar",
    data() {
        return {
            curDate: "",
            curTime: "",
            curDay: "",
            name: "管理者",
            timer: null,
            username: ''
        };
    },
    mounted() {
        this.getDate();
        this.timer = setInterval(() => {
            this.getDate();
        }, 1000);
        this.name =  localStorage.getItem("name")?? sessionStorage.getItem("name");
        
        let loginState = Boolean(localStorage.getItem("loginState")) 
        if (loginState) {
            this.username = localStorage.getItem("username")
        }else {
            this.username = sessionStorage.getItem("username")
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    methods: {
        getDate() {
            const time = new Date();
            (this.curDate = time
                .toLocaleDateString("chinese", { year: "numeric", month: "2-digit", day: "2-digit" })
                .replaceAll("/", "-")),
                (this.curTime = time.toLocaleTimeString("chinese", { hourCycle: "h23" })),
                (this.curDay = time.toLocaleDateString("chinese", { hourCycle: "h23", weekday: "long" }));
        },
        exit() {
            this.$confirm("是否退出登录？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                localStorage.removeItem("token")
                sessionStorage.removeItem("token")
                this.$router.replace("/login")
            }).catch(() => false);
        },
        fullScreen(){
            document.fullscreenElement ?
            document.exitFullscreen() :
            document.documentElement.requestFullscreen();
        },
        showNav(){
            if(this.name == "普通用户"){
                 this.$message({
                message: '未有权限，请联系管理员',
                type: 'warning'
            });
            }else{ this.$eventbus.$emit("open")}
           
           
        }
    },
};
</script>

<style scoped>
.titlebar {
    height: 65px;
    background: linear-gradient(0deg, #3b7eff 0%, #408fff 100%);
    box-shadow: 0px 5px 14px 0px rgba(84, 115, 235, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    color: white;
    flex: none;
}
.title {
    font-size: 1.6rem;
    font-weight: bold;
}
.time-box {
    width: 30%;
}
.control {
    display: flex;
    width: 30%;
    justify-content: flex-end;
}
.icon {
    margin-right: 4px;
}
.btn {
    cursor: pointer;
}
.index-btn {
    color: white;
    border: solid 1px white;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 15px;
}
</style>
